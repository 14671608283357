@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  margin: 0; overflow-x: hidden; overflow-y: auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'HoneyBear';
  src: url('/src/font/Honey\ Bear.ttf') format('truetype');
}
@font-face {
  font-family: 'Komit';
  src: url('/src/font/KOMTIT__.ttf') format('truetype');
  font-weight: normal;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.line-loop-container { overflow: hidden; width: 100%; position: relative; background: #09E5FA; padding: 16px 0;}
.line-loop { animation: scroll 20s linear infinite; white-space: nowrap;}


@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}
